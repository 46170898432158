import {createApp} from "vue";
import App from "@/App";
import {createRouter, createWebHashHistory} from 'vue-router'
const routes = [
    { path: '/', component: ()=>import('@/view/index')  },
    { path: '/detils', component: ()=>import('@/view/detils') },
    { path: '/detilsLaw', component: ()=>import('@/view/detilsLaw') },
    { path: '/detilsJcmer', component: ()=>import('@/view/detilsJcmer') },
    { path: '/downloadFile', component: ()=>import('@/view/downloadFile') },
    { path: '/preview/:id', component: ()=>import('@/view/preview') },
    { path: '/lawhelp', component: ()=>import('@/view/lawHelp') },
    { path: '/pdfOne', component: ()=>import('@/view/pdfOne') },
    { path: '/pdfTwo', component: ()=>import('@/view/pdfTwo') },
    { path: '/pdfThree', component: ()=>import('@/view/pdfThree') },
    { path: '/cdssfs', component: ()=>import('@/view/cdssfs') },
    { path: '/publicLaw', component: ()=>import('@/view/publicLaw') },
]
const router = createRouter({
    history:createWebHashHistory(),
    routes:routes
})
export default router
